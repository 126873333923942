
import React from "react"
import { Stack, Flex, Box, Text, useTheme } from "@chakra-ui/core"

import { useGameStore } from "components/EuropeQuiz/store"
import AchievementBadge from "components/EuropeQuiz/AchievementBadge"

const AchievementsList = ({ from, to, ignoreSound, ...restProps }) => {
  const achievements = useGameStore(state => state.achievements)
  const items = achievements.slice(from, to || achievements.length)
  const { colors } = useTheme()
  return (
    <Stack spacing="1.5rem" alignItems="center" {...restProps}>
      {
        items.map(item => {
          const isComplete = item.total === item.achieved
          return (
            <Flex key={item.name} alignItems="center" flexDirection="column">
              <Box
                mb="-0.75rem"
                border="0.125rem dotted"
                borderColor="mint.700"
                borderRadius="full"
                color="mint.100"
                boxShadow="0 0 0 0.25rem currentcolor"
              >
                <Box
                  w="6rem"
                  h="6rem"
                  bg="mint.200"
                  borderRadius="full"
                  overflow="hidden"
                >
                  <AchievementBadge item={item} ignoreSound={ignoreSound} />
                </Box>
              </Box>
              <Text
                transform="rotate(-4deg)"
                fontSize="0.75rem"
                fontWeight="600"
                lineHeight="0.75rem"
                w="6rem"
                textAlign="center"
                color={isComplete ? colors.blue[700] : colors.mint[700]}
              >
                {item.name.split(" ").map(d =>
                  <Text borderRadius="0.25rem" py="0.0625rem" px="0.25rem" as="span" key={d} display="inline-block" bg="mint.100">
                    {d}
                  </Text>
                )}
              </Text>
              <Text transform="rotate(4deg)" fontSize="0.75rem" fontWeight="600" lineHeight="0.75rem" w="6rem" textAlign="center" mt="0.5rem">
                <Text borderRadius="0.25rem" as="span" p="0.0625rem" display="inline-block" bg="mint.100" color="mint.800">
                  {item.achieved}{"/"}{item.total}
                </Text>
              </Text>
            </Flex>
          )
        })
      }
    </Stack>
  )
}

export default AchievementsList
