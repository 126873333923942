
import React from "react"
import { Stack, Text, Image } from "@chakra-ui/core"
import { AnimatePresence, motion } from "framer-motion"

import capitalCityIcon from "images/capital-city.svg"
import firstLetterIcon from "images/first-letter.svg"
import isoIcon from "images/iso.svg"

import { useGameStore } from "components/EuropeQuiz/store"

const iconNames = {
  "capital": capitalCityIcon,
  "first-letter": firstLetterIcon,
  "iso": isoIcon,
}

const Hints = () => {
  const visibleHints = useGameStore(state => state.visibleHints)
  return (
    <AnimatePresence initial={false}>
      <Stack h="100%" pb="1.25rem" spacing="0" justifyContent="flex-end" px="1.25rem">
        {
          visibleHints.map(hint => {
            return (
              <motion.div
                key={hint.key}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                style={{ marginTop: "1.25rem" }}
              >
                <Stack
                  isInline
                  alignItems="center"
                  fontSize="0.875rem"
                  bg="white"
                  boxShadow="lg"
                  borderRadius="lg"
                  p="1.25rem"
                >
                  <Image src={iconNames[hint.icon]} />
                  <Text fontSize="0.875rem" lineHeight="1.25rem">
                    { hint.label }
                  </Text>
                </Stack>
              </motion.div>
            )
          })
        }
      </Stack>
    </AnimatePresence>
  )
}

export default Hints
