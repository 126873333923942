
import React, { useEffect } from "react"
import { useTheme } from "@chakra-ui/core"
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import useSound from "use-sound"

import achievementCompleteFx from "sounds/pop-burst-04.mp3"
import MotionCircle from "components/MotionCircle"
import { useGameStore } from "components/EuropeQuiz/store"

const AchievementGeographies = React.memo(({ name, isComplete }) => {
  const subregionGeographies = useGameStore(state => state.subregionGeographies)
  const { colors } = useTheme()

  if (!subregionGeographies.length) return null

  return (
    <Geographies geography={subregionGeographies}>
      {({ geographies }) => geographies.map(geo =>
        <Geography
          key={geo.rsmKey}
          geography={geo}
          tabIndex="-1"
          fill={
            isComplete
              ? geo.properties.SUBREGION === name ? colors.blue[700] : colors.mint[400]
              : geo.properties.SUBREGION === name ? colors.mint[700] : colors.mint[400]
          }
        />
      )}
    </Geographies>
  )
})

const AchievementBadge = ({ item, ignoreSound = false }) => {
  const { colors } = useTheme()
  const [play, { sound }] = useSound(achievementCompleteFx)
  const isComplete = item.achieved === item.total
  const enableSound = useGameStore(state => state.sound)

  const pathLength = 1 / item.total * item.achieved

  useEffect(() => {
    if (isComplete && sound && enableSound && !ignoreSound) {
      play()
    }
  }, [isComplete, play, sound, enableSound])

  return (
    <ComposableMap
      width={100}
      height={100}
      style={{ width: "100%", height: "100%" }}
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-10, -53, 0],
        scale: 112,
      }}
    >
      <AchievementGeographies name={item.name} isComplete={isComplete} />
      <circle
        cx={50}
        cy={50}
        r={50}
        fill="none"
        stroke={colors.mint[100]}
        strokeWidth={16}
      />
      <MotionCircle
        cx={50}
        cy={50}
        r={50-4}
        fill="none"
        stroke={colors.blue[700]}
        strokeWidth={4}
        initial={{ pathLength: 0 }}
        animate={{ pathLength }}
        strokeLinecap="round"
      />

    </ComposableMap>
  )
}

export default AchievementBadge
