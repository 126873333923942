
import countries from "../data/countries.json"

// {
//   SOV_A3: d.properties.SOV_A3,
//   ADM0_A3: d.properties.ADM0_A3,
//   names: [d.properties.NAME, d.properties.NAME_LONG, d.properties.FORMAL_EN, d.properties.NAME_SORT],
//   continent: d.properties.CONTINENT,
//   region_un: d.properties.REGION_UN,
//   subregions: d.properties.SUBREGION,
//   region_wb: d.properties.REGION_WB,
// }

export default function getMostSimilar(country) {
  const guess = countries.map(d => {

    const scores = d.names.map(countryName => {
      return similarity(country, countryName)
    }).sort().reverse()

    return { ...d, score: scores[0] }
  }).sort((a, b) => b.score - a.score).slice(0, 3)

  // return guess[0].score > 0.5 ? guess[0] : guess
  return guess[0]
}

export function computeSimilarity(str, names) {
  const scores = names.map((countryName) => {
    return similarity(str, countryName)
  }).sort().reverse()
  return scores[0]
}

function similarity(s1, s2) {
  var longer = s1
  var shorter = s2
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  var longerLength = longer.length
  if (longerLength === 0) {
    return 1.0
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  var costs = []
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i
    for (var j = 0; j <= s2.length; j++) {
      if (i === 0)
        costs[j] = j
      else {
        if (j > 0) {
          var newValue = costs[j - 1]
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue
  }
  return costs[s2.length];
}
