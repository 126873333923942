
import React from "react"
import {
  Stack,
  Box,
  Text,
  Heading,
  Button,
} from "@chakra-ui/core"

import Link from "components/Link"
import SelectDifficulty from "components/EuropeQuiz/SelectDifficulty"
import HowToPlayContent from "components/HowToPlayContent"
import AboutContent from "components/EuropeQuiz/AboutContent"
import InfoHeader from "components/EuropeQuiz/InfoHeader"

import { useGameStore } from "components/EuropeQuiz/store"

import introCover from "images/europe-quiz-intro-cover.png"

const StartButton = (props) => {
  const setCurrentProgress = useGameStore(state => state.setCurrentProgress)
  return (
    <Button
      onClick={() => setCurrentProgress("game")}
      borderRadius="full"
      fontFamily="body"
      height="3.5rem"
      px="2rem"
      bg="blue.500"
      color="white"
      _hover={{
          bg: "blue.600"
        }}
        _focus={{
          bg: "blue.600"
        }}
        _active={{
          bg: "blue.600"
      }}
      {...props}
      >
      {"Start the quiz"}
    </Button>
  )
}

const Intro = () => {
  return (
    <Stack spacing="1.5rem"> 

      <InfoHeader />

      <Stack justifyContent="stretch" flex="1" spacing="1.25rem" alignItems="center" w="100%" maxWidth="90rem" mx="auto" px="1.25rem">
        <Box
          flex="none"
          position="relative"
          width="100%"
          backgroundImage={`url(${introCover})`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="contain"
          h="10vw"
          minHeight="8rem"
          maxHeight="18rem"
        />
        <Stack textAlign="center" spacing="0.5rem">
          <Heading as="h2" fontFamily="heading" fontSize="4rem" textTransform="uppercase">
            {"Europe quiz"}
          </Heading>
        </Stack>
        <Box maxWidth="36rem" textAlign="center">
          <Text fontSize="1.25rem" fontWeight="500" lineHeight="1.5">
            {"How many European countries can you name on the map? Take our Europe geography quiz to challenge yourself and find out!"}
          </Text>
        </Box>

        <SelectDifficulty />

        <Text display={["block", null, null, "none"]} maxWidth="36rem" textAlign="center" lineHeight="1.5" color="gray.500" bg="mint.200" borderRadius="md" p="1rem">
          {"This game is best played on devices with screens larger than 1024px and a physical keyboard."}
        </Text>

        <StartButton display={["none", null, null, "inline-flex"]} />
        <HowToPlayContent />
        <AboutContent />
      </Stack>

      <Box w="100%" maxWidth="90rem" mx="auto" textAlign="center">
        <Text fontSize="0.75rem" lineHeight="1.25rem" py="1.25rem">
          { `© ${ new Date().getFullYear() } `}
          <Link to="https://www.zcreativelabs.com/" textDecoration="underline">
            {"z creative labs"}
          </Link>
          {" GmbH" }
        </Text>
      </Box>
    </Stack>
  )
}

const IntroWrapper = () => {
  return (
    <Box w="100vw" h="100vh" overflow="scroll" zIndex={99999}>
      <Intro />
    </Box>
  )
}

export default IntroWrapper
