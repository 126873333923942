
import React from "react"
import { Button, Icon } from "@chakra-ui/core"

import { useGameStore } from "components/EuropeQuiz/store"

const SoundSwitch = () => {
  const sound = useGameStore(state => state.sound)
  const toggleSound = useGameStore(state => state.toggleSound)
  return (
    <Button
      fontSize="2.5rem"
      bg="transparent"
      borderRadius="full"
      w="3rem"
      h="3rem"
      _hover={{
        bg: "mint.200"
      }}
      _focus={{
        bg: "mint.200"
      }}
      _active={{
        bg: "mint.200"
      }}
      onClick={toggleSound}
    >
      {
        sound
          ? <Icon name="soundOn" w="2rem" h="2rem" />
          : <Icon name="soundOff" w="2rem" h="2rem" />
      }
    </Button>
  )
}

export default SoundSwitch