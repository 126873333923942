
import create from "zustand"
import shuffle from "lodash/shuffle"
import { feature, merge } from "topojson-client"
import groupBy from "lodash/groupBy"
import sortBy from "lodash/sortBy"
import { Howl } from "howler"

import countriesRaw from "data/augmented-countries.json"
import { computeSimilarity } from "utils/similarity"

import clickFx from "sounds/pop-burst-02.mp3"
import successFx from "sounds/pop-burst-01.mp3"
import failFx from "sounds/bounce-twang-spring-short-03.mp3"
import skipFx from "sounds/pop-sucker-01.mp3"
import levelUpFx from "sounds/coins-collect-chime-01.mp3"
import notificationFx from "sounds/notification.mp3"

const skipSound = new Howl({ src: [skipFx] })
const clickSound = new Howl({ src: [clickFx] })
const successSound = new Howl({ src: [successFx] })
const failSound = new Howl({ src: [failFx] })
const levelUpSound = new Howl({ src: [levelUpFx] })
const streakSound = new Howl({ src: [notificationFx] })

const europeanCountries = countriesRaw.filter(d => d.gm_continent === "Europe")
const countries = shuffle(europeanCountries)

function generateHints(country) {
  return [
    { key: "capital", icon: "capital", label: `The capital of this country is ${country.gm_capital}.` },
    { key: "first-letter", icon: "first-letter", label: `The name of this country starts with the letter "${country.gm_name ? country.gm_name[0] : ""}".` },
    { key: "iso", icon: "iso", label: `The ISO3 code of this country is "${country.gm_iso3}".` },
  ]
}

function generateSubregionAchievements(countries) {
  const groupedBySubregion = groupBy(countries, o => o.gm_subregion_un)
  const subregionNames = Object.keys(groupedBySubregion)
  const subregionAchievements = subregionNames.map(name => {
    return {
      name,
      total: groupedBySubregion[name].length,
      achieved: 0,
    }
  })
  return sortBy(subregionAchievements, d => d.name)
}

export const [useGameStore, gameStoreApi] = create(set => ({
  currentProgress: "intro",
  geographies: [],
  subregionGeographies: [],
  land: null,

  startMinutes: 4,
  minutes: 4,
  seconds: 0,

  finalMinutes: 4,
  finalSeconds: 0,

  countries,
  countriesGuessed: [],
  countriesToGuess: countries,
  countriesGuessedIds: [],

  currentMessage: [],

  achievements: generateSubregionAchievements(countries),
  longestStreak: 0,
  longestCurrentStreak: 0,

  currentGuess: countries[0],

  maxHintCount: 2,
  totalHintCount: 0,
  visibleHints: [],
  currentHints: generateHints(countries[0]),

  sound: true,
  difficulty: "default",

  toggleSound: () => {
    clickSound.play()
    set(state => ({ sound: !state.sound }))
  },

  setDifficulty: (difficulty) => {
    const props = difficulty === "learning"
      ? { difficulty, startMinutes: 8, maxHintCount: 3 }
      : difficulty === "default"
        ? { difficulty, startMinutes: 4, maxHintCount: 2 }
        : { difficulty, startMinutes: 2, maxHintCount: 1 }

    set(state => {
      if (state.sound) {
        clickSound.play()
      }
      return props
    })
  },

  setTime: ({ minutes, seconds }) => {
    set({ minutes, seconds })
  },

  getHint: () => {
    set(state => {
      if (!state.currentHints.length || state.visibleHints.length >= state.maxHintCount) return null
    
      const visibleHints = [ ...state.visibleHints, state.currentHints[0] ]
      const currentHints = state.currentHints.slice(1)
      
      if (state.sound) {
        clickSound.play()
      }

      return { visibleHints, currentHints, totalHintCount: state.totalHintCount + 1 }
    })
  },

  guess: (guessString, controls) => {
    set(state => {

      if (!guessString) return null

      const similarityScore = computeSimilarity(guessString, state.currentGuess.names)

      if (similarityScore >= 0.85) {
        const countriesToGuess = state.countriesToGuess.filter(d => d.gm_name !== state.currentGuess.gm_name) || []
        const countriesGuessed = [...state.countriesGuessed, state.currentGuess]
        const countriesGuessedIds = countriesGuessed.map(d => d.gm_iso3)
        const currentGuess = countriesToGuess[0] || {}

        if (state.sound) {
          successSound.play()
        }

        if ([1,15,30,45].includes(countriesGuessedIds.length) && state.sound) {
          levelUpSound.play()
        }

        if ([5,10,20,40].includes(state.longestCurrentStreak + 1) && state.sound) {
          streakSound.play()
        }

        const currentMessage = [
          [15,30,45].includes(countriesGuessed.length) ? `Level ${countriesGuessed.length / 15 + 1}!` : null,
          [5,10,20,40].includes(state.longestCurrentStreak + 1) ? `${state.longestCurrentStreak + 1} in a row!` : null
        ].filter(d => d)

        return {
          countriesGuessed,
          countriesToGuess,
          countriesGuessedIds,
          currentGuess,
          longestCurrentStreak: state.longestCurrentStreak + 1,
          currentMessage: !countriesToGuess.length ? [] : currentMessage,
          currentHints: generateHints(currentGuess),
          visibleHints: [],
          achievements: state.achievements.map(d => {
            return d.name === state.currentGuess.gm_subregion_un ? ({ ...d, achieved: d.achieved + 1 }) : d
          }),
        }
      } else {
        const transition = { duration: 0.075, easing: "easeInOut" }
        const sequence = async () => {
          await controls.start({ x: -20, transition })
          await controls.start({ x: 20, transition })
          await controls.start({ x: -20, transition })
          await controls.start({ x: 20, transition })
          return await controls.start({ x: 0, transition })
        }

        if (state.sound) {
          failSound.play()
        }
        
        sequence()
        return {
          longestCurrentStreak: 0,
          longestStreak: state.longestStreak < state.longestCurrentStreak
            ? state.longestCurrentStreak
            : state.longestStreak,

        }
      }

    })
  },

  setCurrentProgress: (currentProgress) => {
    set(state => {
      if (state.sound && currentProgress === "game") {
        clickSound.play()
      }  
      return { currentProgress, finalMinutes: state.minutes, finalSeconds: state.seconds }
    })
  },

  setShapes: (topo1, topo2) => {
    set({
      geographies: prepareGeographies(topo1),
      subregionGeographies: prepareSubregionGeographies(topo2),
      land: prepareLand(topo1),
    })
  },

  skip: () => {
    set(state => {
      if (!state.countriesToGuess.length) return null
      const countriesToGuess = [...state.countriesToGuess.slice(1), state.countriesToGuess[0]]
      const currentGuess = countriesToGuess[0]

      if (state.sound) {
        skipSound.play()
      }

      return {
        currentGuess,
        countriesToGuess,
        currentHints: generateHints(currentGuess),
        visibleHints: [],
        longestCurrentStreak: 0,
        longest_streak: state.longestStreak < state.longestCurrentStreak
          ? state.longestCurrentStreak
          : state.longestStreak,
      }
    })
  },

  resetGame: (startProgress = "game", enableSound = true) => {
    const newCountryDeck = shuffle(europeanCountries)

    set(state => {
      if (state.sound && enableSound) {
        clickSound.play()
      }

      return {
        currentProgress: startProgress,
        currentGuess: newCountryDeck[0],
        countriesToGuess: newCountryDeck,
        countriesGuessed: [],
        countriesGuessedIds: [],
        currentHints: generateHints(newCountryDeck[0]),
        visibleHints: [],
        totalHintCount: 0,
        longestStreak: 0,
        longestCurrentStreak: 0,
        achievements: generateSubregionAchievements(newCountryDeck),
      }
    })
  },

}))

function prepareGeographies(geographies) {
  const geographiesToExclude = ["ALA","GGY","JEY","FRO"]
  return feature(geographies, geographies.objects[Object.keys(geographies.objects)[0]]).features.filter(d => d.properties.CONTINENT === "Europe" && !geographiesToExclude.includes(d.properties.ISO_A3))
}

function prepareSubregionGeographies(geographies) {
  return feature(geographies, geographies.objects[Object.keys(geographies.objects)[0]]).features
}

function prepareLand(geographies) {
  return merge(geographies, geographies.objects[Object.keys(geographies.objects)[0]].geometries)
}
