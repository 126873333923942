
import React from "react"
import { motion } from "framer-motion"
import { Box } from "@chakra-ui/core"

const transitionConfig = {
  type: "spring",
  stiffness: 200,
  damping: 120,
  mass: 1,
  delay: 0.1,
}

const MotionBox = motion.custom(Box)

const GameView = React.forwardRef((props, ref) => {
  return (
    <MotionBox
      ref={ref}
      transition={transitionConfig}
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bg="mint.50"
      {...props}
    />
  )
})

export default GameView
