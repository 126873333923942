
import React from "react"
import { zeroPad } from "react-countdown"
import { SimpleGrid, Stack, Box, Text, Heading, Button, Icon } from "@chakra-ui/core"

import { useGameStore } from "components/EuropeQuiz/store"
import InfoHeader from "components/EuropeQuiz/InfoHeader"
import Share from "components/EuropeQuiz/Share"
import Newsletter from "components/Newsletter"
import Link from "components/Link"
import AchievementsList from "components/EuropeQuiz/AchievementsList"
import ProductHuntBadge from "components/EuropeQuiz/ProductHunt"

import redRibbon from "images/ribbon-level1.svg"
import goldTrophy from "images/gold.svg"
import silverTrophy from "images/silver.svg"
import bronzeTrophy from "images/bronze.svg"

const Summary = () => {
  const resetGame = useGameStore(state => state.resetGame)
  const longestStreak = useGameStore(state => state.longestStreak)
  const longestCurrentStreak = useGameStore(state => state.longestCurrentStreak)
  const countriesGuessed = useGameStore(state => state.countriesGuessed)
  const startMinutes = useGameStore(state => state.startMinutes)
  const totalHintCount = useGameStore(state => state.totalHintCount)

  const minutes = useGameStore(state => state.finalMinutes)
  const seconds = useGameStore(state => state.finalSeconds)

  const finalStreak = longestCurrentStreak > longestStreak ? longestCurrentStreak : longestStreak

  const trophy = countriesGuessed.length === 45
    ? goldTrophy
    : countriesGuessed.length > 30
      ? silverTrophy
      : countriesGuessed.length > 20
        ? bronzeTrophy
        : redRibbon

  const { length } = countriesGuessed
  const levelReached = length === 45 ? 4 : length >= 30 ? 3 : length >= 15 ? 2 : 1

  const min = seconds ? startMinutes - minutes - 1 : startMinutes - minutes
  const sec = seconds ? 60 - seconds : 0

  return (
    <Box >
      <InfoHeader />
      <Stack justifyContent="stretch" flex="1" spacing="2.5rem" alignItems="center" w="100%" maxWidth="90rem" mx="auto" px="1.25rem">
        <SimpleGrid spacing={["2.5rem", null, null, "4rem"]} justifyContent="center" columns={5}>
          <Stack spacing="1.25rem" textAlign="center">
            <Text fontFamily="heading" fontWeight={700} fontSize="3rem">
              {levelReached}
            </Text>
            <Text fontSize="sm" lineHeight="1.4">{"highest level"}<br/>{"reached"}</Text>
          </Stack>
          <Stack spacing="1.25rem" textAlign="center">
            <Text fontFamily="heading" fontWeight={700} fontSize="3rem">
              {countriesGuessed.length}
            </Text>
            <Text fontSize="sm" lineHeight="1.4">{"number of"}<br/>{"guessed countries"}</Text>
          </Stack>
          <Stack spacing="1.25rem" textAlign="center">
            <Text fontFamily="heading" fontWeight={700} fontSize="3rem">
              {zeroPad(min)}{":"}{zeroPad(sec)}
            </Text>
            <Text fontSize="sm" lineHeight="1.4">{"time to "}<br/>{"complete quiz"}</Text>
          </Stack>
          <Stack spacing="1.25rem" textAlign="center">
            <Text fontFamily="heading" fontWeight={700} fontSize="3rem">
              {totalHintCount}
            </Text>
            <Text fontSize="sm" lineHeight="1.4">{"hints used"}<br/>{"during the game"}</Text>
          </Stack>
          <Stack spacing="1.25rem" textAlign="center">
            <Text fontFamily="heading" fontWeight={700} fontSize="3rem">
              {finalStreak}
            </Text>
            <Text fontSize="sm" lineHeight="1.4">{"longest"}<br/>{"guessing streak"}</Text>
          </Stack>
        </SimpleGrid>
        <Box
          flex="none"
          position="relative"
          width="100%"
          backgroundImage={`url(${trophy})`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="contain"
          h="12vw"
          minHeight="8rem"
          maxHeight="18rem"
        />

        <AchievementsList isInline spacing="5rem" ignoreSound />

        <Stack isInline spacing="1.25rem" justifyContent="center">
          <Button
            borderRadius="full"
            height="3.5rem"
            px="2rem"
            bg="blue.500"
            color="white"
            _hover={{
                bg: "blue.600"
              }}
              _focus={{
                bg: "blue.600"
              }}
              _active={{
                bg: "blue.600"
            }}
            onClick={() => resetGame()}
            fontFamily="body"
          >
            <Icon name="playAgain" w="1.25rem" h="1.25rem" mr="0.75rem" />
            {"Play again"}
          </Button>
          <Share countriesGuessed={countriesGuessed} />
        </Stack>

        <Stack spacing="1.25rem" alignItems="center">
          <Heading as="h2" fontSize="2.5rem" textTransform="uppercase" textAlign="center">
            {"Newsletter"}
          </Heading>
          <Text textAlign="center">
            {"Like this quiz? Sign up to be first to know"}<br/>
            {" when we release a new one!"}
          </Text>
          <Newsletter />
          <Text mt="2.5rem" maxWidth="30rem" textAlign="center">
            { "If you have a minute please also give us an upvote on ProductHunt!" }
          </Text>
          <ProductHuntBadge />
        </Stack>

        <Box w="100%" maxWidth="90rem" mx="auto" textAlign="center" mt="2.5rem">
          <Text fontSize="0.75rem" lineHeight="1.25rem" pb="1.25rem">
            { `© ${ new Date().getFullYear() } `}
            <Link to="https://www.zcreativelabs.com/" textDecoration="underline">
              {"z creative labs"}
            </Link>
            {" GmbH" }
          </Text>
        </Box>
        
      </Stack>
    </Box>
  )
}

const SummeryWrapper = () => {
  return (
    <Box w="100vw" h="100vh" overflow="scroll" zIndex={99999}>
      <Summary />
    </Box>
  )
}

export default SummeryWrapper
