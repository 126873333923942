
import React, { useRef, useState, useEffect } from "react"
import { Box, Text } from "@chakra-ui/core"
import { AnimatePresence, motion } from "framer-motion"

import { useGameStore } from "components/EuropeQuiz/store"

const MotionBox = motion.custom(Box)

const Message = () => {
  const timeout = useRef()
  const [visible, setVisible] = useState()
  const currentMessage = useGameStore(state => state.currentMessage)

  const [first, second, third] = currentMessage

  useEffect(() => {
    if (!first && !second && !third) {
      setVisible(false)
      return
    }

    setVisible(true)

    clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      setVisible(false)
    }, 750)
  }, [first, second, third])
  
  return (
    <AnimatePresence>
      { visible && (
        <MotionBox
          key="message"
          textAlign="center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 2 }}
          transition={{
            type: "spring",
            mass: 1,
            stiffness: 200,
            damping: 16,
          }}
        >
          {
            currentMessage.map((message, i) =>
              <Text
                key={i}
                fontSize={!i ? "3rem" : "1.5rem"}
                lineHeight={!i ? "3rem" : "1.5rem"}
                fontFamily="heading"
                textTransform="uppercase"
                textShadow="-1px -1px 0 #F8FAF8, 1px -1px 0 #F8FAF8, -1px 1px 0 #F8FAF8, 1px 1px 0 #F8FAF8, -2px -2px 0 #F8FAF8, 2px -2px 0 #F8FAF8, -2px 2px 0 #F8FAF8, 2px 2px 0 #F8FAF8, -3px -3px 0 #F8FAF8, 3px -3px 0 #F8FAF8, -3px 3px 0 #F8FAF8, 3px 3px 0 #F8FAF8"
              >
                { message }
              </Text>
            )
          }
        </MotionBox>
      )}
    </AnimatePresence>
  )
}

export default Message
