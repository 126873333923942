
import React, { useRef, useEffect } from "react"
import { Stack, Box, Icon, Text } from "@chakra-ui/core"
import { motion } from "framer-motion"
import { scaleLinear } from "d3-scale"
import Countdown, {zeroPad} from "react-countdown"

import { useGameStore } from "components/EuropeQuiz/store"

const MotionBox = motion.custom(Box)

const ProgressBar = ({ extent, length, ...restProps }) => {
  const scale = scaleLinear()
    .domain(extent)
    .range([0,100])
    .clamp(true)

  return (
    <Box flex="1" h="1rem" bg="mint.300" overflow="hidden" {...restProps}>
      <MotionBox
        h="100%"
        bg="blue.500"
        color="mint.50"
        initial={{ width: 0 }}
        animate={{ width: scale(length) + "%" }}
        transition={{
          type: "spring",
          stiffness: 200,
          damping: 120,
          mass: 1,
        }}
      />
    </Box>
  )
}

const Level = ({ active, isTrophy, ...restProps }) => {
  return (
    <MotionBox
      display="flex"
      flex="none"
      w="4rem"
      h="4rem"
      bg={ active ? "white" : "mint.300" }
      borderRadius="full"
      initial={{ scale: 0.8 }}
      boxShadow={active ? "0 0.25rem 0.5rem rgba(0,0,0,0.1)" : "0 0 0 0.25rem rgba(242, 245, 243, 1)"}
      animate={{
        scale: active ? 1 : 0.8,
        transition: {
          type: "spring",
          mass: 1,
          stiffness: 200,
          damping: 8,
        },
      }}
      {...restProps}
    >
      {
        active ? (
          <Icon
            color="yellow.500"
            name={ isTrophy ? "trophyIcon" : "customStar" }
            w={ isTrophy ? "2rem" : "2.5rem" }
            h={ isTrophy ? "2rem" : "2.5rem" }
            m="auto"
          />
        ) : (
          <Icon
            color="mint.600"
            name={isTrophy ? "trophyIcon" : "grayStar" }
            w={ isTrophy ? "2rem" : "2.5rem" }
            h={ isTrophy ? "2rem" : "2.5rem" }
            m="auto"
          />
        )
      }
    </MotionBox>
  )
}

const renderer = ({ minutes, seconds }) => {
  return (
    <Text
      fontSize="4rem"
      lineHeight="3.5rem"
      fontFamily="heading"
      textShadow="-1px -1px 0 #F8FAF8, 1px -1px 0 #F8FAF8, -1px 1px 0 #F8FAF8, 1px 1px 0 #F8FAF8, -2px -2px 0 #F8FAF8, 2px -2px 0 #F8FAF8, -2px 2px 0 #F8FAF8, 2px 2px 0 #F8FAF8, -3px -3px 0 #F8FAF8, 3px -3px 0 #F8FAF8, -3px 3px 0 #F8FAF8, 3px 3px 0 #F8FAF8"
    >
      {zeroPad(minutes)}{":"}{zeroPad(seconds)}
    </Text>
  )
}

const Counter = React.memo(() => {
  const counterRef = useRef()
  const setTime = useGameStore(state => state.setTime)
  const startMinutes = useGameStore(state => state.startMinutes)
  const setCurrentProgress = useGameStore(state => state.setCurrentProgress)

  const timeLimit = 60000 * startMinutes

  const handleCountdownComplete = () => {
    setCurrentProgress("summary")
  }

  const handleTick = ({ minutes, seconds }) => {
    setTime({ minutes, seconds })
  }

  return (
    <Countdown
      ref={counterRef}
      renderer={renderer}
      date={Date.now() + timeLimit}
      fontFamily="heading" fontSize="4rem" lineHeight="4rem" textAlign="center"
      onTick={handleTick}
      onComplete={handleCountdownComplete}
    />
  )
})

const Progress = () => {
  const countriesGuessedIds = useGameStore(state => state.countriesGuessedIds)
  const countries = useGameStore(state => state.countries)
  const setCurrentProgress = useGameStore(state => state.setCurrentProgress)

  const { length } = countriesGuessedIds

  useEffect(() => {
    if (length === 45) {
      setCurrentProgress("summary")
    }
  }, [length, setCurrentProgress])

  return (
    <Box width="100%">
      <Box textAlign="center" mb="-0.5rem">
        <Counter />
      </Box>
      <Stack isInline spacing="0.5rem" alignItems="center" w="100%" maxWidth="70rem" mx="auto">
        <Level active={length > 0} />
        <ProgressBar extent={[0,15]} length={length} />
        <Level active={length >= 15} />
        <ProgressBar extent={[15,30]} length={length} />
        <Level active={length >= 30} />
        <ProgressBar extent={[30,45]} length={length} />
        <Level isTrophy active={length >= 45} />
      </Stack>
      <Box textAlign="center">
        <Text fontSize="1rem" lineHeight="1rem" mt="-0.5rem" textShadow="-1px -1px 0 #F8FAF8, 1px -1px 0 #F8FAF8, -1px 1px 0 #F8FAF8, 1px 1px 0 #F8FAF8, -2px -2px 0 #F8FAF8, 2px -2px 0 #F8FAF8, -2px 2px 0 #F8FAF8, 2px 2px 0 #F8FAF8">
          {`${countriesGuessedIds.length}/${countries.length}`}
        </Text>
      </Box>
    </Box>
  )
}

export default Progress
