import React from "react"
import {
  Stack,
  Flex,
  Text,
  Heading,
  Kbd,
} from "@chakra-ui/core"

const HowToPlayContent= () => {
  return (
    <Stack spacing="1.25rem" maxWidth="40rem" pt="2rem">
      <Heading textTransform="uppercase" as="h2" fontSize="2.5rem" textAlign="center" pb="0.5rem">
        {"How to play"}
      </Heading>
      <Text lineHeight="1.75">
        {"Type in the name of country highlighted in "} 
        <Flex as="span" display="inline-flex" bg="coral.100" color="coral.700" px="0.25rem" borderRadius="0.25rem">{"red"}</Flex> 
        {" on the map. If you guessed correctly, the highlighted country will turn "} 
        <Flex as="span" display="inline-flex" bg="blue.100" color="blue.700" px="0.25rem" borderRadius="0.25rem">{"blue"}</Flex>
        {". Guess as many countries as possible in the shortest amount of time!"}
      </Text>
      <Text lineHeight="1.75">
        {`If you are stuck, click on "Get a hint" to get clues that might help you guess the country, such as the name of the capital city, the first letter, or the ISO3 country code. Don’t worry if it doesn’t come to you right away, hit "Skip" and the country will appear again later.`} 
      </Text>
      <Text fontSize="md">
        {`To guess press the "Guess" button or simply hit `}
      </Text>
      <Kbd py='0.5rem' px="0.75rem" width="7rem" fontFamily="body">{"Return"}</Kbd>
    </Stack>
  )
}

export default HowToPlayContent