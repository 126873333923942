
import React, { useRef, useState, useEffect } from "react"
import { Grid, Flex, Box, Stack, Button, Input, InputGroup, InputRightElement } from "@chakra-ui/core"
import { useAnimation, motion } from "framer-motion"

import { useGameStore } from "components/EuropeQuiz/store"

import Map from "components/EuropeQuiz/Map"
import Hints from "components/EuropeQuiz/Hints"
import Message from "components/EuropeQuiz/Message"
import Progress from "components/EuropeQuiz/Progress"
import AchievementsList from "components/EuropeQuiz/AchievementsList"
import InfoHeader from "components/EuropeQuiz/InfoHeader"

const Search = React.forwardRef((props, ref) => {
  const guess = useGameStore(state => state.guess)
  const currentProgress = useGameStore(state => state.currentProgress)
  const currentGuess = useGameStore(state => state.currentGuess)
  const controls = useAnimation()
  const [value, setValue] = useState("")

  const currentGuessId = currentGuess.gm_iso3

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      guess(value, controls)
    }
  }

  const handleSubmit = () => {
    guess(value, controls)
  }

  useEffect(() => {
    setValue("")
  }, [currentGuessId])

  useEffect(() => {
    if (currentProgress !== "game") return
    ref.current.focus()
  }, [currentProgress])

  return (
    <motion.div animate={controls}>
      <InputGroup flex="1 1 auto" size="lg" mb="1.25rem" {...props}>
        <Input
          ref={ref}
          type="text"
          borderRadius="full"
          flex="1 1 auto"
          fontFamily="body"
          fontWeight="600"
          fontSize="1rem"
          px="1.5rem"
          placeholder="Enter the name of the highlighted country"
          overflow="visible"
          textTransform="capitalize"
          _placeholder={{
            fontWeight: "500",
            overflow: "visible",
            textTransform: "none"
          }}
          value={value}
          onChange={evt => setValue(evt.target.value)}
          onKeyDown={handleKeyDown}
          pr="7rem"
          borderColor="transparent"
          borderWidth="0.125rem"
          height="3.5rem"
          minHeight="3.5rem"
          maxHeight="3.5rem"
          transition="box-shadow 200ms"
          boxShadow="0 0.25rem 0.5rem rgba(0, 0, 0, 0.07), 0 0.375rem 1rem rgba(0, 0, 0, 0.1)"
          _hover={{
            borderColor: "transparent",
            boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.07), 0 1.25rem 3rem rgba(0, 0, 0, 0.15)"
          }}
          _focus={{
            borderColor: "blue.500",
            boxShadow: " 0 0.25rem 0.5rem rgba(0, 0, 0, 0.07), 0 1.25rem 3rem rgba(0, 0, 0, 0.15)"
          }}
          _active={{
            borderColor: "blue.500",
            boxShadow: " 0 0.25rem 0.5rem rgba(0, 0, 0, 0.07), 0 1.25rem 3rem rgba(0, 0, 0, 0.15)"
          }}
        />
        <InputRightElement width="7rem" pr="0.3125rem" mt="0.25rem">
          <Button
            onClick={handleSubmit}
            isFullWidth
            borderRadius="full"
            fontFamily="body"
            bg="blue.500"
            color="white"
            height="3rem"
            _hover={{
              bg: "blue.600"
            }}
            _focus={{
              bg: "blue.600"
            }}
            _active={{
              bg: "blue.600"
            }}
          >
            { "Guess" }
          </Button>
        </InputRightElement>
      </InputGroup>
    </motion.div>
  )
})

const Game = () => {
  const searchRef = useRef()
  const setCurrentProgress = useGameStore(state => state.setCurrentProgress)
  const resetGame = useGameStore(state => state.resetGame)
  const getHint = useGameStore(state => state.getHint)
  const skip = useGameStore(state => state.skip)

  const handleHint = () => {
    searchRef.current.focus()
    getHint()
  }

  const handleSkip = () => {
    searchRef.current.focus()
    skip()
  }

  return (
    <>
      <InfoHeader resetGame={resetGame} /> 
      <Grid
        gridTemplateColumns="12rem 12rem 1fr 12rem 12rem"
        gridTemplateRows="9rem 1fr 9rem"
        gridGap="0"
        h="100vh"
        mt="-9rem"
      >
        <Box
          gridRow="1 / -1"
          gridColumn="1 / -1"
          bg="mint.100"
        >
          <Map />
        </Box>

        <Flex gridRow="1 / span 1" gridColumn={["1 / -1", null, "2 / -2", null, "3 / -3"]} alignItems="flex-end">
          <Progress />
        </Flex>   

        <Flex gridRow="2 / -2" gridColumn="3 / -3" alignItems="center" justifyContent="center">
          <Message />
        </Flex>

        <Box gridRow="-2 / -1" gridColumn={["1 / -1", null, "2 / -2", null, "3 / -3"]} position="relative">
          <Box maxWidth="48rem" mx="auto">
            <Search ref={searchRef} />
          </Box>
          <Stack isInline spacing="0.5rem" justifyContent="center">
            <Button
              onClick={handleHint}
              borderRadius="full"
              height="2.5rem"
              bg="amber.500"
              color="blue.900"
              fontWeight="600"
              fontFamily="body"
              px="2rem"
              _hover={{
                  bg: "amber.600"
                }}
                _focus={{
                  bg: "amber.600"
                }}
                _active={{
                  bg: "amber.600"
                }}
              >
              { "Get hint" }
            </Button>
            <Button
              onClick={handleSkip}
              borderRadius="full"
              bg="mint.50"
              color="blue.900"
              fontWeight="600"
              fontFamily="body"
              px="2rem"
              _hover={{
                  bg: "mint.100"
                }}
                _focus={{
                  bg: "mint.100"
                }}
                _active={{
                  bg: "mint.100"
                }}
              >
                { "Skip" }
              </Button>
          </Stack>
        </Box>

        <Box gridRow="2 / -2" gridColumn="1 / span 1" alignSelf="center">
          <AchievementsList from={0} to={2} />
        </Box>

        <Box gridRow="2 / -2" gridColumn="-2 / -1" alignSelf="center">
          <AchievementsList from={2} />
        </Box>

        <Box gridRow="2 / -2" gridColumn="1 / span 2" zIndex={999}>
          <Hints />
        </Box>

        <Box pb="1.25rem" gridRow="-2 / -1" gridColumn="-2 / -1" display="flex" justifyContent="center" alignItems="flex-end">
          <Button
            onClick={() => setCurrentProgress("summary")}
            height="2.5rem"
            color="blue.900"
            fontFamily="body"
            bg="transparent"
            _hover={{
              bg: "transparent"
            }}
            _active={{
              bg: "transparent"
            }}
            _focus={{
              bg: "transparent"
            }}
          >
            { "Give up" }
          </Button>
        </Box>

      </Grid>
    </>
  )
}

export default Game
