import React from "react"
import {
  Box,
  Text,
  Button,
  RadioButtonGroup,
  Icon,
} from "@chakra-ui/core"

import { useGameStore } from "components/EuropeQuiz/store"

const CustomRadio = React.forwardRef(({ isChecked, isDisabled, rotation, label, textColor, ...restProps }, ref) => {
  return (
    <Button
      ref={ref}
      bg="transparent"
      position="relative"
      aria-checked={isChecked}
      role="radio"
      isDisabled={isDisabled}
      {...restProps}
      width="auto"
      height="auto"
      transform={`rotate(${rotation})`}
      display="inline-grid"
      px="0"
      alignItems="center"
      _hover={{
        bg: "transparent",
      }}
      _focus={{
        bg: "transparent",
      }}
      _active={{
        bg: "transparent",
      }}
    >
      <Box
        as="svg" 
        gridRow="1"
        gridColumn="1 / span 1"
        w={140}
        h={84}
        viewBox="0 0 144 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        overflow="visible"
      >
        <path
          d="M30 10.4C34.6391 10.4 38.4 6.63919 38.4 2H49.6C49.6 6.63919 53.3609 10.4 58 10.4C62.6391 10.4 66.4 6.63919 66.4 2H77.6C77.6 6.63919 81.3609 10.4 86 10.4C90.6391 10.4 94.4 6.63919 94.4 2H105.6C105.6 6.63919 109.361 10.4 114 10.4C118.639 10.4 122.4 6.63919 122.4 2H133.6C133.6 6.63919 137.361 10.4 142 10.4V21.6C137.361 21.6 133.6 25.3608 133.6 30C133.6 34.6392 137.361 38.4 142 38.4V49.6C137.361 49.6 133.6 53.3608 133.6 58C133.6 62.6392 137.361 66.4 142 66.4V77.6C137.361 77.6 133.6 81.3608 133.6 86H122.4C122.4 81.3608 118.639 77.6 114 77.6C109.361 77.6 105.6 81.3608 105.6 86H94.4C94.4 81.3608 90.6391 77.6 86 77.6C81.3609 77.6 77.6 81.3608 77.6 86H66.4C66.4 81.3608 62.6391 77.6 58 77.6C53.3609 77.6 49.6 81.3608 49.6 86H38.4C38.4 81.3608 34.6391 77.6 30 77.6C25.3609 77.6 21.6 81.3608 21.6 86H10.4C10.4 81.3608 6.63914 77.6 2 77.6L2 66.4C6.63914 66.4 10.4 62.6392 10.4 58C10.4 53.3608 6.63914 49.6 2 49.6L2 38.4C6.63914 38.4 10.4 34.6392 10.4 30C10.4 25.3608 6.63914 21.6 2 21.6V10.4C6.63914 10.4 10.4 6.63919 10.4 2L21.6 2C21.6 6.63919 25.3609 10.4 30 10.4Z"
          fill="#E5EBE7"
          strokeWidth={3}
          stroke={isChecked ? "#039DBF" : "transparent"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Box>
      <Text
        as="span"
        fontFamily="heading"
        textTransform="uppercase"
        fontSize="1.5rem"
        color={isChecked ? "default" : "mint.700"} 
        position="absolute"
        top="50%"
        left={0}
        right={0}
        transform="translateY(-50%)"
      >
        {label}
      </Text>
      <Box
        position="absolute"
        top="-1rem"
        right="-1rem"
        display={isChecked ? "flex" : "none"}
        bg="white"
        boxShadow="0 0.25rem 0.5rem rgba(0,0,0,0.1)"
        borderRadius="full"
        w="2rem"
        h="2rem"
      >
        <Icon name="check" color="blue.500" w="1.5rem" h="1.5rem" m="auto"/>
      </Box>
    </Button>
  )
})

const SelectDifficulty = (props) => {
  const difficulty = useGameStore(state => state.difficulty)
  const countries = useGameStore(state => state.countries)
  const startMinutes = useGameStore(state => state.startMinutes)
  const maxHintCount = useGameStore(state => state.maxHintCount)
  const setDifficulty = useGameStore(state => state.setDifficulty)
  return (
    <Box {...props}>
      <RadioButtonGroup
        isInline
        spacing="5rem"
        my="2rem"
        display={["none", null, null, "block"]}
        value={difficulty}
        onChange={setDifficulty}
        textAlign="center"
      >
        <CustomRadio value="learning" rotation="5deg" label="Learning" />
        <CustomRadio value="default" rotation="-5deg" label="Default" />
        <CustomRadio value="difficult" rotation="5deg" label="Difficult" />
      </RadioButtonGroup>
      <Text textAlign="center" fontSize="0.875rem" fontWeight={500} color="gray.500">
        { `Guess all ${countries.length} countries in  ` }
        <Text as="span" color="blue.500" fontWeight={500}>{`${startMinutes} minutes`}</Text>
        { " with " }
        <Text as="span" color="blue.500" fontWeight={500}>{`${maxHintCount}${maxHintCount > 1 ? " hints" : " hint"}`}</Text>
        { " per country." }
      </Text>
    </Box>
  )
}

export default SelectDifficulty
