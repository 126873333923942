  
import React from "react"
import { zeroPad } from "react-countdown"
import { Icon } from "@chakra-ui/core"

import Link from "components/Link"

import { useGameStore } from "components/EuropeQuiz/store"

const Share = ({
  url = "https://geography.games/europe-quiz/",
  ...restProps
}) => {
  const countriesGuessedIds = useGameStore(state => state.countriesGuessedIds)
  const minutes = useGameStore(state => state.finalMinutes)
  const seconds = useGameStore(state => state.finalSeconds)
  const startMinutes = useGameStore(state => state.startMinutes)

  const { length } = countriesGuessedIds
  const levelReached = length === 45 ? 4 : length >= 30 ? 3 : length >= 15 ? 2 : 1

  const min = seconds ? startMinutes - minutes - 1 : startMinutes - minutes
  const sec = seconds ? 60 - seconds : 0

  const twitterRoot = "http://twitter.com/intent/tweet"
  const shareText = `I guessed ${countriesGuessedIds.length} European countries in ${min ? zeroPad(min) + ":" : ""}${zeroPad(sec)} ${min ? "minutes" : "seconds"}, and got to level ${levelReached}${countriesGuessedIds.length === 45 ? "🏆" : "" } in the Geography Games Europe Quiz. ${countriesGuessedIds.length === 45 ? "Try it yourself!" : "Can you beat my score?" }`
  const encodedShareText = encodeURIComponent(shareText)
  const shareUrl = `${twitterRoot}?url=${url}&text=${encodedShareText}&original_referer=${url}`

  return (
    <Link
      to={shareUrl}
      borderRadius="full"
      height="3.5rem"
      lineHeight="3.5rem"
      fontFamily="body"
      px="2rem"
      bg="mint.200"
      color="blue.900"
      fontWeight="600"
      _hover={{
          bg: "mint.300"
        }}
        _focus={{
          bg: "mint.300"
        }}
        _active={{
          bg: "mint.300"
      }}
      {...restProps}
      >
      <Icon name="twitter" w="1.25rem" h="1.25rem" mr="0.75rem" />
      {"Share score!"}
    </Link>
  )
}

export default Share