
import React, { useEffect } from "react"
import { AnimatePresence } from "framer-motion"
import { json } from "d3-fetch"

import { useGameStore, gameStoreApi } from "components/EuropeQuiz/store"
import GameView from "components/EuropeQuiz/GameView"
import Intro from "components/EuropeQuiz/Intro"
import Game from "components/EuropeQuiz/Game"
import Summary from "components/EuropeQuiz/Summary"

const EuropeQuiz = () => {
  const resetGame = useGameStore(state => state.resetGame)
  const currentProgress = useGameStore(state => state.currentProgress)
  const setShapes = useGameStore(state => state.setShapes)

  useEffect(() => {
    Promise.all([
      json("/europe.json"),
      json("/europe-subregions.json"),
    ]).then(([ topo1, topo2 ]) => {
      setShapes(topo1, topo2)
    })
  }, [setShapes])

  useEffect(() => {
    return () => {
      resetGame("intro", false)
      gameStoreApi.destroy()
    }
  }, [resetGame])

  return (
    <>
      <AnimatePresence initial={false}>
        {currentProgress === "intro" && (
          <GameView
            key="intro"
            initial={{ scale: 1, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 1.2, opacity: 0 }}
          >
            <Intro />
          </GameView>
        )}
        {currentProgress === "game" && (
          <GameView
            key="game"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Game />
          </GameView>
        )}
        {currentProgress === "summary" && (
          <GameView
            key="summary"
            initial={{ scale: 1.2, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 1.2, opacity: 0 }}
          >
            <Summary />
          </GameView>
        )}
      </AnimatePresence>
    </>
  )
}

export default EuropeQuiz
