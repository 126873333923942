import React from "react"
import { motion } from "framer-motion"

const MotionCircle = ({ cx, cy, r, ...restProps}) => {
  return (
    <motion.path
      d={`
        M ${cx - r}, ${cy}
        a ${r},${r} 0 1,1 ${r*2},0
        a ${r},${r} 0 1,1 -${r*2},0
      `}
      transform={`rotate(90 ${cx} ${cy})`}
      strokeLinecap="round"
      transition={{
        type: "spring",
        stiffness: 200,
        damping: 120,
        mass: 1,
      }}
      {...restProps}
    />
  )
}

export default MotionCircle