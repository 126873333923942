
import React from "react"

import SEO from "components/SEO"
import EuropeQuiz from "components/EuropeQuiz"

import europeQuizCover from "images/europe-quiz-og-image.png"

const EuropeQuizPage = () => {
  return (
    <>
      <SEO
        title="Europe Geography Quiz — Geography Games"
        description="Play this fun geography quiz to find out how many European countries you can name. Choose a difficulty level to learn, improve, and challenge your geography skills. Expand your knowledge and discover the countries of Europe in a fun way!"
        cover={europeQuizCover}
        path="/europe-quiz/"
      />
      <EuropeQuiz />
    </>
  )
}

export default EuropeQuizPage
