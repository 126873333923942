import React from "react"
import {
  Stack,
  Text,
  Heading,
} from "@chakra-ui/core"

import Link from "components/Link"

const HowToPlayContent= () => {
  return (
    <Stack spacing="1.5rem" maxWidth="40rem" pt="2rem">
      <Heading textTransform="uppercase" as="h2" fontSize="2.5rem" textAlign="center" pb="0.5rem">
        {"About this quiz"}
      </Heading>
      <Text lineHeight="1.75">
        {`We created this geography quiz to make learning the names and geographic locations of countries more fun.`} 
      </Text>
      <Text lineHeight="1.75">
        {"Can you point out Malta on the map? How about Demmark, Italy, or Andorra? This quiz will help you learn the locations and shapes of European countries in a fun and playful way. If you get stuck, the hints hide some extra information, such as the capital city, the first letter of the country name, and its ISO3 code."}
      </Text>
      <Text lineHeight="1.75">
        {"If you're just starting out, choose the learning level to get 3 hints per country and 10 minutes to guess all the names. Don't worry if you can't guess all of them the first time around. The summary table will show you which European sub-regions you know better, and which ones you still need to work on. Just skip the countries you don't know, and guess as many as you can."}
      </Text>
      <Text lineHeight="1.75">
        {"The default mode will show you a maxium of 2 hints per country, and give you 5 minutes to complete the quiz. This is a nice challenge if you have already tried similar quizzes and know a lot of European country names."}
      </Text>
      <Text lineHeight="1.75">
        {"Are you feeling confident? Challenge yourself and choose the most difficult level to test your knowledge of European countries with one hint per country, and only 2 minutes to guess them all!"}
      </Text>
      <Text lineHeight="1.75">
        {"This geographic quiz was made with 💕 by "}
        <Link to="https://www.zcreativelabs.com/" color="blue.500">{"z creative labs"}</Link>
        {" using "}
        <Link to="https://www.react-simple-maps.io/" color="blue.500">{"react-simple-maps"}</Link>
        {" and "}
        <Link to="https://chakra-ui.com/" color="blue.500">{"Chakra UI."}</Link>
      </Text> 
    </Stack>
  )
}

export default HowToPlayContent