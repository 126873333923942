
import React from "react"
import { Box, Grid, Image } from "@chakra-ui/core"
import { Link as GLink } from "gatsby"

import SoundSwitch from "components/EuropeQuiz/SoundSwitch"

import favicon from "images/favicon.svg"

const InfoHeader = () => {
  return (
    <Grid
      gridTemplateColumns={["6rem 1fr 6rem", null, "12rem 12rem 1fr 12rem 12rem"]}
      gridGap="0"
      h="9rem"
      alignItems="center"
      zIndex={99999}
      position="relative"
    >
      <Box gridColumn="1 / 2" textAlign="center">
        <GLink to="/">
          <Image src={favicon} w="4rem" h="4rem" display="inline-block" />
        </GLink>
      </Box>
      <Box gridColumn="-2 / -1" textAlign="center">
        <SoundSwitch />
      </Box>
    </Grid>
  )
}

export default InfoHeader
