
import React from "react"
import { useTheme, Text } from "@chakra-ui/core"
import { ComposableMap, Geographies, Geography, Sphere, Marker } from "react-simple-maps"
import { PatternWaves } from "@vx/pattern"

import { useGameStore } from "components/EuropeQuiz/store"

const exceptions = ["MCO","SMR","VAT","AND","LIE","MLT"]

const Land = () => {
  const theme = useTheme()
  const land = useGameStore(state => state.land)
  return (
    <Geographies geography={[land]}>
      {({ geographies }) =>  (
        <Geography
          geography={geographies[0]}
          fill={theme.colors.mint[200]}
          stroke={theme.colors.mint[50]}
          tabIndex="-1"
          strokeWidth={8}
          strokeLinejoin="bevel"
          paintOrder="stroke fill"
          style={{
            default: { outline: "none" },
            hover: { outline: "none" },
            pressed: { outline: "none" },
          }}
        />
      )}
    </Geographies>
  )
}

const AllGeographies = ({ geographies }) => {
  const theme = useTheme()
  const countries = useGameStore(state => state.countries)
  return (
    <>
      {
        geographies.map(geo => (
          <Geography
            key={geo.rsmKey}
            geography={geo}
            stroke={theme.colors.gray[200]}
            strokeWidth={2}
            strokeLinejoin="bevel"
            fill={theme.colors.mint[50]}
            paintOrder="stroke fill"
            tabIndex="-1"
            style={{
              default: { outline: "none" },
              hover: { outline: "none" },
              pressed: { outline: "none" },
            }}
          />
        ))
      }
      {
        exceptions.map(exception => {
          const d = countries.find(s => s.gm_iso3 === exception)
          return (
            <Marker key={exception} coordinates={[d.gm_lon, d.gm_lat]} tabIndex="-1">
              <circle
                r={5}
                stroke={theme.colors.mint[700]}
                strokeWidth={2}
                strokeLinejoin="bevel"
                fill={theme.colors.mint[50]}
                paintOrder="stroke fill"
              />
            </Marker>
          )
        })
      }
    </>
  )
}

const CountriesGuessed = ({ geographies }) => {
  const theme = useTheme()
  const countries = useGameStore(state => state.countries)
  const countriesGuessedIds = useGameStore(state => state.countriesGuessedIds)
  const guessedGeographies = geographies.filter(d => countriesGuessedIds.includes(d.properties.ISO_A3))

  const circles = countries.filter(d => exceptions.includes(d.gm_iso3))

  return (
    <>
      {
        guessedGeographies.map(geo => (
          <Geography
            key={geo.rsmKey + "_guessed"}
            geography={geo}
            stroke={theme.colors.blue[700]}
            strokeWidth={2}
            strokeLinejoin="bevel"
            fill={theme.colors.blue[50]}
            paintOrder="stroke fill"
            tabIndex="-1"
            style={{
              default: { outline: "none" },
              hover: { outline: "none" },
              pressed: { outline: "none" },
            }}
          />
        ))
      }
      {
        circles.map(geo => {
          const isGuessed = countriesGuessedIds.includes(geo.gm_iso3)
          return isGuessed ? (
            <Marker key={geo.gm_iso3 + "_guessed"} coordinates={[geo.gm_lon, geo.gm_lat]} tabIndex="-1">
              <circle
                r={5}
                stroke={theme.colors.blue[700]}
                strokeWidth={2}
                strokeLinejoin="bevel"
                fill={theme.colors.blue[50]}
                paintOrder="stroke fill"
              />
            </Marker>
          ) : (
            <Marker key={geo.gm_iso3 + "_not_guessed"} coordinates={[geo.gm_lon, geo.gm_lat]} tabIndex="-1">
              <circle
                r={5}
                stroke={theme.colors.gray[200]}
                strokeWidth={2}
                strokeLinejoin="bevel"
                fill={theme.colors.mint[50]}
                paintOrder="stroke fill"
              />
            </Marker>
          )
        })
      }
    </>
  )
}

const CurrentGeography = ({ geographies }) => {
  const theme = useTheme()
  const countries = useGameStore(state => state.countries)
  const currentGuess = useGameStore(state => state.currentGuess)
  if (exceptions.includes(currentGuess.gm_iso3)) {
    const c = countries.find(s => s.gm_iso3 === currentGuess.gm_iso3)
    return (
      <Marker coordinates={[c.gm_lon, c.gm_lat]} tabIndex="-1">
        <circle
          r={5}
          stroke={theme.colors.coral[700]}
          strokeWidth={2}
          strokeLinejoin="bevel"
          fill={theme.colors.coral[500]}
          paintOrder="stroke fill"
        />
      </Marker>
    )
  }
  
  const currentGeography = geographies.find(s => s.properties.ISO_A3 === currentGuess.gm_iso3)

  if (!currentGeography) return null

  return (
    <Geography
      geography={currentGeography}
      stroke={theme.colors.coral[700]}
      strokeWidth={2}
      strokeLinejoin="bevel"
      fill={theme.colors.coral[500]}
      paintOrder="stroke fill"
      tabIndex="-1"
      style={{
        default: { outline: "none" },
        hover: { outline: "none" },
        pressed: { outline: "none" },
      }}
    />
  )
}

const Map  = () => {
  const allGeographies = useGameStore(state => state.geographies)
  const theme = useTheme()

  if (!allGeographies.length) return null

  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{ scale: 590, rotate: [-15, -55, 0], center: [0, -2.5] }}
      style={{ width: "100%", height: "100%" }}
      preserveAspectRatio="xMidYMid meet"
    >

      <PatternWaves
        id="waves"
        height={6}
        width={6}
        stroke={theme.colors.mint[200]}
        strokeWidth={0.75}
      />

      <Sphere fill="url(#waves)" />

      <Marker coordinates={[-35,48.3]}>
        <Text as="text" textTransform="uppercase" color="mint.100" stroke="currentcolor" strokeWidth={5} strokeLinejoin="bevel" fill="currentcolor" fontSize="0.625rem">
          { "Atlantic ocean" }
        </Text>
        <Text as="text" textTransform="uppercase" color="#A6ACAF" fill="currentcolor" fontSize="0.625rem">
          { "Atlantic ocean" }
        </Text>
      </Marker>

      <Land />

      {/* <Geographies geography="https://raw.githubusercontent.com/tinius/hillshade-json/master/hillshade.json">
        {({ geographies }) => {
          console.log(geographies, "hi")
          return null
        }}
      </Geographies> */}

      <Geographies geography={allGeographies}>
        {({ geographies }) => (
          <>
            <AllGeographies geographies={geographies} />
            <CountriesGuessed geographies={geographies} />
            <CurrentGeography geographies={geographies} />
          </>
        )}
      </Geographies>

    </ComposableMap>
  )
}

export default Map
